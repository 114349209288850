import { ChangeEvent, ElementRef, forwardRef, useRef } from 'react';
import { useMergedRef } from '../../../hooks/use-merged-ref';
import { ButtonProps } from '../../Button/Button';
import { Icon } from '../../Icon/Icon';
import { IconButton } from '../../IconButton/IconButton';

export type FilesPayload =
  | {
      /** Allows user to select multiple files */
      multiple: true;
      /** Fired when files are picked */
      onChange: (payload: File[]) => void;
    }
  | {
      multiple?: false;
      onChange: (payload: File | null) => void;
    };

export type FileButtonProps = {
  /** Types of files to accept in the input, example: `"image/png,image/jpeg"` */
  accept?: string;

  /** Input name attribute */
  name?: string;

  /** Disabling the file button */
  disabled?: boolean;

  /** Props for file button */
  buttonProps?: ButtonProps;
} & FilesPayload;

type InputElementType = ElementRef<'input'>;

export const FileButtonField = forwardRef<InputElementType, FileButtonProps>(
  (
    { name, accept, disabled, multiple, onChange, buttonProps },
    forwardedRef,
  ) => {
    const inputRef = useRef<InputElementType>();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (multiple) {
        onChange?.(Array.from(event.currentTarget!.files!));
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      } else {
        onChange?.(event.currentTarget!.files![0] || null);
      }
    };

    const openFileExplorer = () => {
      if (!disabled) inputRef.current?.click();
    };

    return (
      <IconButton
        {...buttonProps}
        aria-label="Attach file"
        size="small"
        disabled={disabled}
        onClick={openFileExplorer}
      >
        <Icon name="attach_file" color="text-content-link" size="large" />
        <input
          id="fileInput"
          ref={useMergedRef(forwardedRef, inputRef)}
          type="file"
          className="hidden"
          onChange={handleChange}
          name={name}
          accept={accept}
          multiple={multiple}
          disabled={disabled}
        />
      </IconButton>
    );
  },
);

FileButtonField.displayName = 'FileButton';
