import { faAt, faMap, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetPatientSummaryQuery } from '@pm/graphql';
import { FunctionComponent } from 'react';
import { ClipboardButton } from '../ClipboardButton';
import { PhoneNumberDisplay } from '../PhoneNumberDisplay/PhoneNumberDisplay';

type Props = {
  patientId: string;
};

export const PatientSummary: FunctionComponent<Props> = ({ patientId }) => {
  const { data } = useGetPatientSummaryQuery({
    variables: { id: patientId },
  });
  const patient = data?.user;

  return (
    <div className="text-grey-700 sm:mb-10 mb-5 flex sm:items-end justify-between gap-6 flex-col sm:flex-row">
      <div
        className="text-3xl"
        data-testid="patientName"
      >{`${patient?.firstName} ${patient?.lastName}`}</div>
      <div data-testid="patientProvince">
        <FontAwesomeIcon
          icon={faMap}
          className="text-content-positive mr-2"
          title="Province"
        />
        {patient?.profile?.province?.name}
      </div>
      <div data-testid="patientPhoneNumber">
        <FontAwesomeIcon
          icon={faPhone}
          className="text-content-positive mr-2"
          title="Phone Number"
        />
        <PhoneNumberDisplay phone={patient?.phone || null} />
      </div>
      <div data-testid="patientEmail">
        <FontAwesomeIcon
          icon={faAt}
          className="text-content-positive mr-2"
          title="Email Address"
        />
        <span className="mr-2">{patient?.email}</span>
        <ClipboardButton text={patient?.email ?? ''} />
      </div>
    </div>
  );
};
