import clsx from 'clsx';
import * as React from 'react';

/* -------------------------------------------------------------------------------------------------
 * ButtonContent
 * -----------------------------------------------------------------------------------------------*/

type ButtonContentProps = {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  children?: React.ReactNode;
};

const ButtonContent = ({
  leftIcon,
  rightIcon,
  children,
}: ButtonContentProps) => {
  return (
    <>
      {leftIcon && <ButtonIcon className="mr-2">{leftIcon}</ButtonIcon>}
      {children}
      {rightIcon && <ButtonIcon className="ml-2">{rightIcon}</ButtonIcon>}
    </>
  );
};

/* -------------------------------------------------------------------------------------------------
 * ButtonIcon
 * -----------------------------------------------------------------------------------------------*/

type ButtonIconElement = React.ElementRef<'span'>;
type ButtonIconProps = React.ComponentPropsWithoutRef<'span'>;

const ButtonIcon = React.forwardRef<ButtonIconElement, ButtonIconProps>(
  (props, forwardedRef) => {
    return (
      <span
        {...props}
        className={clsx(
          'inline-flex h-5 w-5 shrink-0 self-center',
          props.className,
        )}
        ref={forwardedRef}
      />
    );
  },
);

ButtonIcon.displayName = 'ButtonIcon';

export { ButtonContent, ButtonIcon };
