import * as ProgressPrimitive from '@radix-ui/react-progress';
import { type VariantProps, cva } from 'class-variance-authority';
import clsx from 'clsx';
import invariant from 'tiny-invariant';

const BAR_HEIGHT = '20px';

const progressBar = cva(`h-[${BAR_HEIGHT}] bg-accent-subtle`);

export type ProgressBarProps = VariantProps<typeof progressBar> &
  ProgressPrimitive.ProgressProps & {
    progress: number; // <= 100
    label?: string;
    rounded?: boolean;
  };

export const ProgressBar = ({
  progress,
  label,
  rounded,
  ...props
}: ProgressBarProps) => {
  invariant(
    progress >= 0 && progress <= 100,
    'The progress prop must be greater than or equal to 0 and less than or equal to 100',
  );

  const barStyles = clsx(progressBar(), rounded && 'rounded-xs');
  const indicatorStyles = clsx(
    `h-[${BAR_HEIGHT}] bg-positive transition-all ease-in-out duration-300`,
    rounded && 'rounded-xs',
  );

  return (
    <ProgressPrimitive.Root
      {...props}
      value={progress}
      aria-label="Progress bar"
      aria-busy={progress < 100 && 'true'}
      aria-describedby={props['aria-describedby'] ?? 'Progress bar'}
      className="flex flex-col gap-xxs text-center"
    >
      <ProgressPrimitive.Indicator className={barStyles}>
        <div className={indicatorStyles} style={{ width: `${progress}%` }} />
      </ProgressPrimitive.Indicator>
      {label && (
        <div className="text-label text-content-subdued mr-auto">{label}</div>
      )}
    </ProgressPrimitive.Root>
  );
};
