import {
  DetailedHTMLProps,
  FunctionComponent,
  TextareaHTMLAttributes,
  useId,
} from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { CharacterCount } from '../CharacterCount';
import { baseTextStyles } from '../forms/shared-input-styles';
import { Text } from '@pm/ui';

type Props = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  name: string;
  label?: string;
  testId?: string;
  characterCount?: number;
  supportingText?: string | null;
};

export const HookFormTextArea: FunctionComponent<Props> = ({
  name,
  label,
  placeholder,
  onChange,
  className,
  testId,
  rows = 2,
  required = false,
  characterCount,
  supportingText,
}) => {
  const id = useId();
  const { register, formState, getFieldState, setValue, getValues } =
    useFormContext();
  const { error, isTouched } = getFieldState(name, formState);

  return (
    <div className="space-y-2">
      {label && (
        <label
          htmlFor={id}
          className={clsx('text-grey-500 text-sm font-medium', {
            "after:ml-1 after:text-red-500 after:content-['*']": required,
          })}
        >
          {label}
        </label>
      )}
      <textarea
        {...register(name)}
        id={id}
        placeholder={placeholder}
        // TODO: Remove this once we properly handle labels, as name shouldn't be
        // announced to screen readers
        aria-label={label ? undefined : name}
        rows={rows}
        name={name}
        onChange={(e) => {
          setValue(name, e.target.value, {
            shouldTouch: true,
            shouldValidate: true,
            shouldDirty: true,
          });
          if (onChange) onChange(e);
        }}
        aria-invalid={error && isTouched ? true : undefined}
        className={clsx(baseTextStyles, className)}
        data-testid={testId}
      />
      <div className="flex-col items-center">
        {error && (
          <p className="text-sm font-medium border-none text-critical-dark mb-2">
            {error.message}
          </p>
        )}
        {supportingText && (
          <Text color="contentSubdued" size="bodyS" className="-mt-2">
            {supportingText}
          </Text>
        )}
        {characterCount && (
          <div className="flex ml-auto -mt-2 justify-end">
            <CharacterCount
              count={getValues(name)?.length ?? 0}
              limit={characterCount}
            />
          </div>
        )}
      </div>
    </div>
  );
};
