import { type ForwardedRef } from 'react';
import * as Select from '@radix-ui/react-select';
import { twMerge } from 'tailwind-merge';
import { type VariantProps, cva } from 'class-variance-authority';
import { type InputBaseProps, inputBase } from '../base';
import { Icon } from '../../Icon/Icon';

const dropdownTrigger = cva('flex items-center text-content-subdued w-full');

type DropdownTriggerProps = VariantProps<typeof dropdownTrigger> &
  InputBaseProps & {
    ref: ForwardedRef<HTMLButtonElement>;
    placeholder?: string;
    ariaLabel?: string;
    id?: string;
  };

/**
 * Responsible for toggling the dropdown menu.
 *
 * @argument placeholder - Placeholder text to display when no value is selected. Dropdown's value takes precedence.
 */
export const DropdownTrigger = ({
  placeholder,
  ariaLabel,
  intent,
  id,
}: DropdownTriggerProps) => {
  return (
    <Select.Trigger
      aria-label={ariaLabel}
      className={twMerge(dropdownTrigger(), inputBase({ intent }))}
      id={id}
    >
      <Select.Value
        placeholder={placeholder}
        className="text-content-subdued"
      />
      <Select.Icon className="ml-auto">
        <Icon name="expand_more" />
      </Select.Icon>
    </Select.Trigger>
  );
};
