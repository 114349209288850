import { Checkbox, CheckboxGroup, InputLabel } from '@pm/ui';
import { ComponentProps, ElementType, useEffect, useId, useState } from 'react';
import { useController } from 'react-hook-form';
import { errorMessage, formField, supportingMessage } from './FormField';

type Option = ComponentProps<typeof Checkbox>;

type FormCheckboxGroupProps = Omit<
  ComponentProps<typeof CheckboxGroup>,
  'children'
> & {
  label?: React.ReactNode;
  name: string;
  options: Option[];
  supportingText?: string;
  CheckboxComponent: ElementType;
  noneValue?: string | null;
  freeText?: {
    on: string;
    placeholder?: string;
    label?: React.ReactNode;
    name: string;
  };
};

const getExtraneousValues = (fieldValues: string[], options: Option[]) => {
  return fieldValues.find((fieldValue) =>
    options.every((option) => option.value !== fieldValue),
  );
};

/**
 * Component wrapper for checkbox group elements to be used with React Hook Forms.
 */
export const FormCheckboxGroup = ({
  label,
  name,
  options,
  supportingText,
  CheckboxComponent,
  noneValue = 'none',
  freeText,
  ...restProps
}: FormCheckboxGroupProps) => {
  const { field, fieldState } = useController({ name, defaultValue: [] });
  const { error } = fieldState;
  const generatedLabelId = useId();
  const labelId = label ? generatedLabelId : undefined;
  const [freeTextSelected, setFreeTextSelected] = useState(false);
  const [freeTextValue, setFreeTextValue] = useState('');
  // As per design system specs, an error takes precedence over supporting text
  const showSupportingText = !error && supportingText;
  const errorId = error ? `${name}-error` : undefined;
  const supportingTextId = showSupportingText
    ? `${name}-description`
    : undefined;

  useEffect(() => {
    const extraneousValues = getExtraneousValues(field.value, options);
    let isFreeTextSelected = false;

    if (
      (freeText && field.value.includes(extraneousValues)) ||
      field.value.includes('')
    ) {
      isFreeTextSelected = true;
    }
    setFreeTextSelected(isFreeTextSelected);
    if (extraneousValues) {
      setFreeTextValue(extraneousValues ?? '');
    }
  }, [setFreeTextSelected, field.value, freeText, options, freeTextSelected]);

  return (
    <div className={formField()}>
      {label && <InputLabel id={generatedLabelId}>{label}</InputLabel>}
      <CheckboxGroup
        aria-describedby={errorId || supportingTextId}
        aria-label={restProps['aria-label']}
        aria-labelledby={labelId ?? restProps['aria-labelledby']}
      >
        {options.map((checkboxProps) =>
          checkboxProps.value !== '' ? (
            <CheckboxComponent
              {...field}
              {...checkboxProps}
              key={`${name}-${checkboxProps.value}`}
              checked={field.value.includes(checkboxProps.value)}
              onChange={(checkedState: $TSFixMe) => {
                if (
                  noneValue &&
                  checkboxProps.value === noneValue &&
                  checkedState === true
                ) {
                  setFreeTextSelected(false);
                  field.onChange([checkboxProps.value]);
                  return;
                }
                const updatedValue = [...field.value];
                if (checkedState === true) {
                  updatedValue.push(checkboxProps.value);
                  if (noneValue && updatedValue.includes(noneValue)) {
                    updatedValue.splice(updatedValue.indexOf(noneValue), 1);
                  }
                } else {
                  updatedValue.splice(
                    updatedValue.indexOf(checkboxProps.value),
                    1,
                  );
                }

                field.onChange(updatedValue);
              }}
            />
          ) : (
            <CheckboxComponent
              {...field}
              {...checkboxProps}
              key={`${name}-${checkboxProps.value}`}
              checked={freeTextSelected}
              onChange={(checkedState: $TSFixMe) => {
                const updatedValue = [...field.value];
                if (checkedState === true) {
                  setFreeTextSelected(true);
                  if (noneValue && updatedValue.includes(noneValue)) {
                    updatedValue.splice(updatedValue.indexOf(noneValue), 1);
                  }
                  updatedValue.push(freeTextValue);
                } else {
                  setFreeTextSelected(false);
                  updatedValue.splice(updatedValue.indexOf(freeTextValue), 1);
                  setFreeTextValue('');
                }
                field.onChange(updatedValue);
              }}
              freeText={
                freeText &&
                freeTextSelected && {
                  ...freeText,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    const updatedValue = [...field.value];
                    if (updatedValue.includes(freeTextValue)) {
                      updatedValue.splice(
                        updatedValue.indexOf(freeTextValue),
                        1,
                      );
                    }
                    updatedValue.push(event.target.value);
                    field.onChange(updatedValue);
                    setFreeTextValue(event.target.value);
                  },
                  value: freeTextValue,
                }
              }
            />
          ),
        )}
      </CheckboxGroup>
      {error && (
        <p id={errorId} className={errorMessage()}>
          {error.message}
        </p>
      )}
      {showSupportingText && (
        <p id={supportingTextId} className={supportingMessage()}>
          {supportingText}
        </p>
      )}
    </div>
  );
};
