import { useRef } from 'react';
import { useToggle } from '@pm/core';

/**
 * Utility hook to show a confirmation modal and wait for the user to confirm or cancel.
 * Will always resolve with `{ confirmed: boolean }` and optionally additional fields.
 *
 * @example
 * ```
 * const { confirm } = useConfirmationModal(); // Defaults to `{ confirmed: boolean }`
 *
 * const handleConfirmation = async () => {
 *  const { confirmed } = await confirm();
 *  console.log(confirmed ? "Confirmed" : "Cancelled");
 * }
 * ```
 *
 * @example
 * ```
 * const { confirm } = useConfirmationModal<{ reason?: string }>();
 *
 * const handleConfirmation = async () => {
 *  const { confirmed, reason } = await confirm();
 * }
 * ```
 */

export const useConfirmationModal = <
  ResolveArgs extends object = Record<string, never>,
>() => {
  const [showConfirmationModal, handlers] = useToggle();

  type ConfirmedResolveType = ResolveArgs & { confirmed: true };
  type CanceledResolveType = { confirmed: false };
  type ResolveType = ConfirmedResolveType | CanceledResolveType;

  const resolveConfirmationPromise = useRef<(value: ResolveType) => void>();
  const confirmPromise = useRef<Promise<ResolveType>>();
  const confirm = async () => {
    handlers.on();
    confirmPromise.current = new Promise((resolve) => {
      resolveConfirmationPromise.current = resolve;
    });

    return await confirmPromise.current;
  };

  const handleConfirm = (args?: ResolveArgs) => {
    resolveConfirmationPromise.current?.(
      Object.assign({}, args, { confirmed: true }),
    );
    handlers.off();
  };

  const handleClose = () => {
    resolveConfirmationPromise.current?.({ confirmed: false });
    confirmPromise.current = undefined;
    handlers.off();
  };

  const handleState = () => {
    handlers.toggle();
  };

  return {
    confirm,
    handleConfirm,
    handleClose,
    showConfirmationModal,
    handleState,
  };
};
