export const shuffle = <T>(array: T[]): T[] => {
  const shuffled = [...array];
  for (
    let currentIndex = shuffled.length - 1;
    currentIndex > 0;
    currentIndex--
  ) {
    const nextIndex = currentIndex + 1;
    const randomIndex = Math.floor(Math.random() * nextIndex);
    swapListElements({ list: shuffled, currentIndex, newIndex: randomIndex });
  }
  return shuffled;
};

const swapListElements = <T>({
  list,
  currentIndex,
  newIndex,
}: {
  list: T[];
  currentIndex: number;
  newIndex: number;
}) => {
  [list[currentIndex], list[newIndex]] = [list[newIndex], list[currentIndex]];
};
