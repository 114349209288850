import { createContext, FunctionComponent, PropsWithChildren } from 'react';
import {
  Brand,
  BRAND_DISPLAY_NAME,
  BRAND_NAME,
  FormattingContext,
} from '../types';

type EnvironmentContextType = {
  directUploadsUrl: string;
  googleAuthUrl: string;
  apiHost: string;
  environment: string;
  brand: Brand;
  brandDisplayName: (typeof BRAND_DISPLAY_NAME)[Brand];
  formattingContext: FormattingContext;
};

type Props = Pick<
  EnvironmentContextType,
  'apiHost' | 'environment' | 'brand' | 'formattingContext'
>;

export const EnvironmentContext = createContext<EnvironmentContextType>({
  directUploadsUrl: '',
  googleAuthUrl: '',
  apiHost: '',
  environment: 'staging',
  brand: BRAND_NAME.Freddie,
  brandDisplayName: BRAND_DISPLAY_NAME[BRAND_NAME.Freddie],
  formattingContext: 'provider',
});

export const EnvironmentProvider: FunctionComponent<
  PropsWithChildren<Props>
> = ({ children, apiHost, environment, brand, formattingContext }) => (
  <EnvironmentContext.Provider
    value={{
      directUploadsUrl: `${apiHost}/uploads/direct_uploads`,
      googleAuthUrl: `${apiHost}/auth/google_oauth2/callback`,
      apiHost,
      environment,
      brand,
      brandDisplayName: BRAND_DISPLAY_NAME[brand],
      formattingContext: formattingContext,
    }}
  >
    {children}
  </EnvironmentContext.Provider>
);
