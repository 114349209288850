import * as ToastPrimitive from '@radix-ui/react-toast';
import { cva, type VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';
import { ToastIcon } from './ToastIcon';

const DEFAULT_TOAST_DURATION = 5000;
const TOAST_WIDTH = '360px';

const toast = cva(
  `flex justify-center mb-auto shadow-m rounded-s gap-m text-body-l max-w-[${TOAST_WIDTH}] focus:outline-none max-w-[379px]`,
  {
    variants: {
      intent: {
        info: 'bg-info-subtle border-info',
        warning: 'bg-warning-subtle border-warning',
        positive: 'bg-positive-subtle border-positive',
        negative: 'bg-negative-subtle border-negative',
      },
    },
    defaultVariants: {
      intent: 'info',
    },
  },
);

const toastViewport = cva(
  `fixed flex flex-col flex-col w-[${TOAST_WIDTH}] z-2147483647 top-0 right-0 m-l`,
);

export type ToastIntentType = VariantProps<typeof toast>['intent'];

type ToastProps = {
  content: string | React.ReactNode;
  title?: string;
  label?: string;
} & VariantProps<typeof toast> &
  Omit<ToastPrimitive.ToastProps, 'content'>;

export const Toast = ({
  open,
  content,
  label,
  title,
  intent,
  type,
  duration = DEFAULT_TOAST_DURATION,
  ...props
}: ToastProps) => {
  const toastStyles = twMerge(toast({ intent }));

  return (
    <ToastPrimitive.Provider
      swipeDirection="right"
      label={label ?? 'Toast notification'}
    >
      <ToastPrimitive.Root
        {...props}
        open={open}
        aria-label={label ?? 'Toast notification'}
        duration={duration}
        className={toastStyles}
      >
        <div className="flex justify-center items-center self-start pl-m py-m">
          <ToastIcon intent={intent} />
        </div>
        <div className="flex flex-col flex-grow py-m gap-xxs self-center">
          {title && (
            <ToastPrimitive.Title className="text-heading-xs flex-grow">
              {title}
            </ToastPrimitive.Title>
          )}
          <ToastPrimitive.Description>{content}</ToastPrimitive.Description>
        </div>
        <ToastPrimitive.Close asChild aria-label="Close Toast">
          <div className="my-xxs">
            <IconButton intent="ghost" size="large">
              <Icon name="close" size="large" />
            </IconButton>
          </div>
        </ToastPrimitive.Close>
      </ToastPrimitive.Root>
      <ToastPrimitive.Viewport className={toastViewport()} />
    </ToastPrimitive.Provider>
  );
};
