export const FormsortFlows = {
  USA: {
    HivCareIntake: 'usa-hiv-screener',
    PrepIntake: 'freddie-usa-questionnaire',
    SEQ: 'freddie-usa-seq',
  },
} as const;

export const FormsortStepIds = {
  QuestionnaireSubmitted: 'questionnaire_submitted',
} as const;

export type FormsortFlow = keyof typeof FormsortFlows;
