import { ApolloQueryResult } from '@apollo/client/index.js';
import { GetMePatientQuery, useGetMePatientQuery } from '@pm/graphql';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  type FC,
} from 'react';

type PatientProfileContextType = {
  profile: NonNullable<Required<GetMePatientQuery>['me']>;
  refetch: () => Promise<ApolloQueryResult<GetMePatientQuery>>;
  loading: boolean;
};

export const PatientProfileContext = createContext<
  PatientProfileContextType | undefined
>(undefined);

export const usePatientProfileContext = () => useContext(PatientProfileContext);

export const PatientProfileProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data, refetch, loading } = useGetMePatientQuery();
  const provinceCode = data?.me?.profile?.province?.code;
  if (provinceCode) {
    localStorage.setItem('PROVINCE', provinceCode);
  }
  const steps = data?.me?.workflowSteps ?? [];
  const hasReceivedRX = steps.some(
    (step) => step.type === 'Steps::FulfillPrescription',
  );
  sessionStorage.setItem('HAS_RECEIVED_RX', hasReceivedRX.toString());

  useEffect(() => {
    if (data?.me && data.me.id) {
      sessionStorage.setItem('USER_ID', data.me.id);
    }
  }, [data]);

  if (!data?.me) {
    return null;
  }

  return (
    <PatientProfileContext.Provider
      value={{
        profile: data.me,
        refetch,
        loading,
      }}
    >
      {children}
    </PatientProfileContext.Provider>
  );
};
