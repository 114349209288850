import { LegacyRef, ReactNode } from 'react';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

export type SelectProps = {
  name: string;
  options: string[] | Array<{ text: ReactNode; value: string }>;
  disabled?: boolean;
  className?: string;
  cols?: number;
  dataCy?: string;
  innerRef?: LegacyRef<HTMLDivElement>;
  onSelect?: (value: string) => void;
};

export const HookFormSelect = ({
  name,
  options,
  className,
  innerRef,
  onSelect,
  dataCy,
  disabled = false,
  cols = 1,
}: SelectProps) => {
  const { register, setValue, getValues, getFieldState, formState, control } =
    useFormContext();

  const { error } = getFieldState(name, formState);

  let _options: Array<{ text: ReactNode; value: string }>;
  if (typeof options[0] === 'string') {
    _options = (options as string[]).map((option) => ({
      text: option,
      value: option,
    }));
  } else {
    _options = options as Array<{ text: ReactNode; value: string }>;
  }

  return (
    <div className="mt-6 w-full" ref={innerRef}>
      <Controller
        control={control}
        name={name}
        render={({ field: { name } }) => (
          <div
            className={clsx('mx-auto grid gap-x-4 gap-y-2', {
              'grid-cols-1': cols === 1,
              'grid-cols-2': cols === 2,
              'grid-cols-3': cols === 3,
            })}
          >
            {_options.map(
              (option: { text: ReactNode; value: string }, index: number) => (
                <button
                  {...register(name)}
                  key={index}
                  data-cy={`${dataCy}-${index}`}
                  type="button"
                  role="radio"
                  aria-checked={getValues(name) === option.value}
                  disabled={disabled}
                  onClick={() => {
                    setValue(name, option.value, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });
                    if (onSelect) onSelect(option.value);
                  }}
                  className={clsx(
                    'border-grey-500 w-full rounded-lg border px-5 py-4 transition duration-200 ease-in-out',
                    getValues(name) === option.value
                      ? 'bg-grey-600 text-white' // selected state
                      : 'text-grey-600 bg-white',
                    className,
                  )}
                >
                  {option.text}
                </button>
              ),
            )}
          </div>
        )}
      />
      {error && <p className="mt-2 text-red-500">{error.message}</p>}
    </div>
  );
};
