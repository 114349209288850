import { useId, type ComponentPropsWithoutRef, type ElementType } from 'react';
import { Controller } from 'react-hook-form';
import { type VariantProps, cva } from 'class-variance-authority';
import { InputLabel } from '@pm/ui';

export const formField = cva('flex flex-col', {
  variants: { space: { xxs: 'gap-xxs', xs: 'gap-xs', s: 'gap-s', m: 'gap-m' } },
  defaultVariants: { space: 's' },
});
export const errorMessage = cva('text-negative text-label');
export const supportingMessage = cva('text-label text-content-subdued');

type InputProps =
  | Omit<ComponentPropsWithoutRef<'input'>, 'className'>
  | Omit<ComponentPropsWithoutRef<'select'>, 'className'>;

export type FormFieldProps = InputProps &
  VariantProps<typeof formField> & {
    name: string;
    Component: ElementType;
    label?: string;
    supportingText?: string;
    defaultIntent?: string;
    placeholder?: string;
  };

/**
 * Component wrapper for single input elements to be used with React Hook Forms.
 */
export const FormField = ({
  id: idProp,
  name,
  Component,
  label,
  supportingText,
  placeholder,
  disabled,
  space = 's',
  defaultIntent = 'default',
  ...restProps
}: FormFieldProps) => {
  const _id = useId();
  const id = idProp || _id;

  return (
    <div className={formField({ space })}>
      <Controller
        name={name}
        render={({ field, fieldState: { error } }) => {
          const intent = error ? 'error' : defaultIntent;
          // As per design system specs, an error takes precedence over supporting text
          const showSupportingText = !error && supportingText;
          const errorId = error ? `${name}-error` : undefined;
          const supportingTextId = showSupportingText
            ? `${name}-description`
            : undefined;

          return (
            <div className={formField({ space })}>
              {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
              <Component
                {...field}
                {...restProps}
                id={id}
                aria-describedby={errorId || supportingTextId}
                intent={intent}
                disabled={disabled}
                placeholder={placeholder}
                onChange={field.onChange}
                ref={null}
              />
              {error && (
                <p id={errorId} className={errorMessage()}>
                  {error.message}
                </p>
              )}
              {showSupportingText && (
                <p id={supportingTextId} className={supportingMessage()}>
                  {supportingText}
                </p>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};
