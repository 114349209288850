import { Spinner } from '@pm/ui';

export const LoadingPage = ({ classOverride }: { classOverride?: string }) => (
  <div className={classOverride ?? 'flex h-screen w-full flex-col'}>
    <div className="flex-1" />
    <div className="w-full text-center">
      <Spinner />
    </div>
    <div className="flex-1" />
  </div>
);
