import { Fragment, ReactNode } from 'react';
import { type VariantProps, cva } from 'class-variance-authority';
import { Text } from '../Text/Text';

const spinner = cva('fill-accent animate-spin mx-auto pointer-none');

export type SpinnerProps = VariantProps<typeof spinner> & {
  header?: string;
  body?: string;
  overlay?: boolean;
};

export const Spinner = ({ header, body, overlay = false }: SpinnerProps) => {
  const OverlayMaybe = overlay ? Overlay : Fragment;

  return (
    <OverlayMaybe>
      <div className="text-center space-y-xl">
        <div>
          {header && (
            <Text size="headingXS" color="contentSubdued" role="alert">
              {header}
            </Text>
          )}
          {body && <Text color="contentSubdued">{body}</Text>}
        </div>
        <svg
          width="49"
          height="49"
          role="status"
          aria-label="loading"
          aria-live="polite"
          aria-busy="true"
          className={spinner()}
        >
          <path d="M24 2.86197e-07C37.2548 4.44259e-07 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 1.28135e-07 37.2548 2.86197e-07 24L6 24C6 33.9411 14.0589 42 24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6L24 2.86197e-07Z" />
        </svg>
      </div>
    </OverlayMaybe>
  );
};

const Overlay = ({ children }: { children: ReactNode }) => {
  return (
    <div
      role="dialog"
      className="fixed overflow-hidden inset-0 items-center flex justify-center bg-overlay/30"
    >
      {children}
    </div>
  );
};
