import { FunctionComponent, ReactNode, useState } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { QuestionnaireLabel, variants } from '../QuestionnaireLabel';
import { Spacer } from '../Spacer';
import { HookFormStyledField } from '../StyledField/HookFormStyledField';
import { ToggleButton } from '../ToggleButton';
import { StyledInput } from '../forms/StyledInput';

export type RadioFieldProps = {
  name: string;
  label?: string;
  labelVariant?: variants;
  sublabel?: string;
  options: string[] | { text: ReactNode; value: string }[];
  freeText?: {
    on: string;
    placeholder?: string;
    rightAlign?: boolean;
    label?: string;
    labelVariant?: 'smallBlack';
    name: string;
  };
  cols?: number;
  fullYMargin?: boolean;
  onSelect?: () => void;
  fade?: boolean;
  disabled?: boolean;
  optionalDescription?: {
    name: string;
    placeholder: string;
  };
};

export const HookFormRadioField: FunctionComponent<RadioFieldProps> = ({
  name,
  label,
  labelVariant = 'muted',
  sublabel,
  options,
  freeText,
  cols = 2,
  fullYMargin = true,
  onSelect,
  fade = false,
  disabled = false,
  optionalDescription,
}) => {
  const { formState, register, setValue, getValues } = useFormContext();
  const { errors } = formState;

  let _options: { text: ReactNode; value: string }[];
  if (typeof options[0] === 'string') {
    _options = (options as string[]).map((option) => ({
      text: option,
      value: option,
    }));
  } else {
    _options = options as { text: ReactNode; value: string }[];
  }

  const manageFreeTextSelectedState = () => {
    if (!freeText) return false;
    const initialValue = getValues(name) ?? '';

    const freeTextValue = getValues(freeText.name);
    const isSameFieldName = name === freeText.name;

    function optionsHasValue(value: string) {
      return _options.some((option) => option.value === value);
    }

    // initial blank state
    if (initialValue === '') {
      return false;
    }

    if (isSameFieldName) {
      return !optionsHasValue(freeTextValue);
    }

    // different names
    if (initialValue === freeText.on) {
      return true;
    }

    return false;
  };

  const [freeTextSelected, setFreeTextSelected] = useState(
    manageFreeTextSelectedState(),
  );

  const getOpacity = (index: number) =>
    index === 0
      ? ''
      : index === 1
        ? 'opacity-60'
        : index === 2
          ? 'opacity-30'
          : index === 3
            ? 'opacity-10'
            : index === 4
              ? 'opacity-0'
              : 'opacity-0';

  return (
    <div className={clsx('w-full', fullYMargin === true && 'my-8 sm:my-12')}>
      <div className="space-y-4">
        {label && (
          <QuestionnaireLabel variant={labelVariant}>
            {label}
          </QuestionnaireLabel>
        )}
        {sublabel && <div className="mb-6">{sublabel}</div>}
        <div
          className={clsx(
            `mx-auto grid grid-cols-1 gap-x-4 gap-y-2`,
            cols === 2 && 'sm:grid-cols-2',
            cols === 3 && 'sm:grid-cols-3',
            fade && 'opacity-30',
          )}
        >
          {_options.map((option, index) =>
            freeText?.on !== option.value ? (
              fade && index > 3 ? null : (
                <div key={`${name}-${option.value}`}>
                  <ToggleButton
                    key={option.value}
                    text={option.text}
                    {...register(name)}
                    selected={getValues(name) === option.value}
                    disabled={disabled}
                    className={fade ? clsx(getOpacity(index)) : ''}
                    aria-label={label}
                    onClick={() => {
                      if (onSelect) onSelect();
                      setValue(name, option.value, {
                        shouldTouch: true,
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                      setFreeTextSelected(false);
                    }}
                  />
                  {optionalDescription && getValues(name) === option.value && (
                    <HookFormStyledField
                      name={optionalDescription.name}
                      placeholder={optionalDescription.placeholder}
                      inputStyle="italic border border-grey-500"
                    />
                  )}
                </div>
              )
            ) : (
              <div key={`${name}-free`} className="space-y-xs">
                <ToggleButton
                  text={option.text}
                  {...register(name)}
                  selected={freeTextSelected}
                  disabled={disabled}
                  onClick={() => {
                    if (onSelect) onSelect();
                    if (freeText?.name) {
                      setValue(
                        name,
                        freeText.name === name ? '' : option.value,
                      );
                      setFreeTextSelected(true);
                    } else {
                      if (!freeTextSelected)
                        setValue(freeText?.name ?? '', {
                          shouldTouch: true,
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      setFreeTextSelected(true);
                    }
                  }}
                />
                {freeText && freeTextSelected && (
                  <div className={clsx(freeText.rightAlign && 'sm:col-end-3')}>
                    {freeText?.label && (
                      <div>
                        <QuestionnaireLabel
                          variant={freeText.labelVariant ?? 'baseGreen'}
                        >
                          {freeText.label}
                        </QuestionnaireLabel>
                        <Spacer size={2} />
                      </div>
                    )}
                    <StyledInput
                      {...register(freeText.name ?? '', {
                        onChange: (event) => {
                          setValue(name, event.target.value, {
                            shouldTouch: true,
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        },
                      })}
                      placeholder={freeText?.placeholder}
                      autoComplete="off"
                      aria-label={freeText.label}
                    />
                  </div>
                )}
              </div>
            ),
          )}
        </div>
      </div>
      <ErrorMessage
        name={name}
        as="p"
        className="mt-2 text-red-400"
        errors={errors}
      />
    </div>
  );
};
