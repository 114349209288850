const formsortEnvironmentLookup: Record<string, string | undefined> = {
  production: undefined,
  staging: 'staging',
  development: 'development-dev-purposemed',
};

export const formsortEnvFromEnv = () => {
  // if environment is undefined infer we are in development, since our
  // .env file has not been setting VITE_ENVIRONMENT historically
  const environment = import.meta.env.VITE_ENVIRONMENT || 'development';

  return formsortEnvironmentLookup[environment];
};
