import { Icon, Text } from '@pm/ui';
import { trackEvent } from '@pm/analytics';
import { useTranslation } from 'react-i18next';

type GetSupportProps = {
  restartPrep: boolean;
};

export const GetSupport = ({ restartPrep = false }: GetSupportProps) => {
  const { t } = useTranslation('patient-dashboard', {
    keyPrefix: 'GetSupport',
  });
  const supportOptions = t('Options', { returnObjects: true });
  const supportLinks = Object.entries(supportOptions).map(
    ([key, value]: [string, string]) => {
      return {
        text: key,
        href: value,
      };
    },
  );

  const renewalSupportList = [
    ...supportLinks.slice(0, 1),
    ...supportLinks.slice(2),
  ];
  const supportList = restartPrep ? supportLinks.slice(1) : renewalSupportList;

  return (
    <div className="border-t-2 pt-[60px] mt-[20px]">
      <Text color="contentSubdued" size="headingS">
        {t('Title')}
      </Text>
      <ul className="divide-y divide-gray-200">
        {supportList.map(({ text, href }) => (
          <li
            key={text}
            className="py-4 first:pt-6 flex items-center gap-s content-center"
          >
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-xs"
              onClick={() => {
                trackEvent('Button Clicked', {
                  buttonText: text,
                  buttonName: text,
                  targetPath: href,
                  currentPath: window.location.pathname,
                  currentPageName: 'PatientDashboardPage',
                });
              }}
            >
              <Text color="contentSubdued" size="bodyL">
                {text}
              </Text>
              <Icon
                name="open_in_new"
                size="small"
                color="text-content-subdued"
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
