import clsx from 'clsx';
import * as React from 'react';
import { textInputStyles } from './shared-input-styles';

/* -------------------------------------------------------------------------------------------------
 * StyledInput
 * -----------------------------------------------------------------------------------------------*/

type StyledInputElement = React.ElementRef<'input'>;
type StyledInputProps = React.ComponentPropsWithoutRef<'input'>;

const StyledInput = React.forwardRef<StyledInputElement, StyledInputProps>(
  ({ className: classNameProp, ...props }, forwardedRef) => {
    const className = clsx(textInputStyles, classNameProp);
    return <input {...props} className={className} ref={forwardedRef} />;
  },
);

StyledInput.displayName = 'StyledInput';

export { StyledInput };
