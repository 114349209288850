import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { XMarkIcon } from '@heroicons/react/20/solid';

type Props = {
  onClose: () => void;
  className?: string;
};

export const ModalHeading = ({
  children,
  onClose,
  className,
}: PropsWithChildren<Props>) => {
  const computedClassName = clsx(
    'text-lg text-grey-900 font-medium',
    className,
  );
  const { t } = useTranslation('global');
  const closeLabel = t('CloseDialog');

  return (
    <div className="flex items-start items-center justify-between rounded-t border-b px-6 py-4">
      <h1 className={computedClassName}>{children}</h1>
      <button
        type="button"
        className="text-grey-500 hover:bg-grey-200 hover:text-grey-700 ml-auto inline-flex items-center rounded-lg bg-transparent p-1 text-sm"
        aria-label={closeLabel}
        onClick={onClose}
      >
        <XMarkIcon className="ml-auto h-6 hover:cursor-pointer" />
      </button>
    </div>
  );
};
