import EmbedFlow from '@formsort/react-embed';
import { FormName, trackEvent } from '@pm/analytics';
import { ComponentProps } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormsortStepIds } from './formsort';
import { formsortEnvFromEnv } from './formsortHelpers';

type FormsortFormProps = Pick<
  ComponentProps<typeof EmbedFlow>,
  | 'flowLabel'
  | 'variantLabel'
  | 'queryParams'
  | 'onFlowLoaded'
  | 'onFlowFinalized'
  | 'onStepLoaded'
  | 'onStepCompleted'
  | 'onRedirect'
> & { formName: FormName; trackingEnabled?: boolean };

const FormsortForm = ({
  formName,
  trackingEnabled = true,
  flowLabel: defaultFlowLabel,
  variantLabel: defaultVariantLabel,
  queryParams: customQueryParams = [],
  onFlowLoaded,
  onFlowFinalized,
  onStepLoaded,
  onStepCompleted,
  onRedirect,
  ...props
}: FormsortFormProps) => {
  const [searchParams] = useSearchParams();
  const flowLabel = searchParams.get('flow');
  const variantLabel = searchParams.get('variant');
  const utmSource = searchParams.get('utm_source') ?? '';
  const utmMedium = searchParams.get('utm_medium') ?? '';
  const utmCampaign = searchParams.get('utm_campaign') ?? '';
  const utmTerm = searchParams.get('utm_term') ?? '';
  const utmContent = searchParams.get('utm_content') ?? '';

  const formsortEnv = formsortEnvFromEnv();

  const defaultQueryParams: [string, string][] = [
    ['utm_source', utmSource],
    ['utm_medium', utmMedium],
    ['utm_campaign', utmCampaign],
    ['utm_term', utmTerm],
    ['utm_content', utmContent],
  ];

  const queryParams = Array.from(
    new Map([...defaultQueryParams, ...customQueryParams]).entries(),
  );

  return (
    <div
      className="flex-1"
      // TODO: When https://github.com/formsort/oss/issues/134 is done, remove data-testid
      data-testid="formsort-form"
    >
      <EmbedFlow
        {...props}
        clientLabel="qqrXtaPDlr"
        flowLabel={flowLabel ? flowLabel : defaultFlowLabel}
        variantLabel={variantLabel ? variantLabel : defaultVariantLabel}
        embedConfig={{
          style: {
            width: '100%',
            height: '100%',
          },
        }}
        formsortEnv={formsortEnv}
        queryParams={queryParams}
        onFlowLoaded={(payload) => {
          if (trackingEnabled) {
            trackEvent(`${formName} Flow Loaded`);
          }
          onFlowLoaded?.(payload);
        }}
        onFlowFinalized={(payload) => {
          const trackFinalizedEvents = () => {
            trackEvent(`${formName} Flow Finalized`);

            const stepId = payload?.stepId;
            const isEligible =
              stepId === FormsortStepIds.QuestionnaireSubmitted;
            if (isEligible) {
              trackEvent(`${formName} Eligible`);
            }
          };

          if (trackingEnabled) {
            trackFinalizedEvents();
          }

          onFlowFinalized?.(payload);
        }}
        onStepLoaded={(payload) => {
          const { answers } = payload;

          if (trackingEnabled) {
            trackEvent(`${formName} Step Loaded`, {
              answerLength: answers ? Object.entries(answers).length : 0,
            });
          }

          onStepLoaded?.(payload);
        }}
        onStepCompleted={(payload) => {
          const { answers } = payload;

          if (trackingEnabled) {
            trackEvent(`${formName} Step Completed`, {
              answerLength: answers ? Object.entries(answers).length : 0,
            });
          }

          onStepCompleted?.(payload);
        }}
        onRedirect={(redirectData) => {
          if (trackingEnabled) {
            trackEvent(`${formName} Redirected`, {
              currentPath: window.location.pathname,
              targetPath: redirectData.url,
            });
          }

          onRedirect?.(redirectData);
        }}
      />
    </div>
  );
};

export default FormsortForm;
